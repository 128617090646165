<template>
  <NewsroomContainer></NewsroomContainer>
</template>

<script>
  import NewsroomContainer from '@/components/Newsroom/index';
  import { i18nHelper } from '@/utils';

  export default {
    name: 'FAQ',
    metaInfo: {
      title: i18nHelper.getMessage('meta.title.faq'),
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: i18nHelper.getMessage('meta.description.faq')
        }
      ]
    },
    components: {
      NewsroomContainer
    }
  };
</script>

<style lang="scss"></style>
