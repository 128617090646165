<template>
  <v-card
    class="card-container"
    :max-height="height.container"
    @click="openNewsTab(url)"
  >
    <v-img
      v-if="imgUrl"
      :src="imgUrl"
      :height="height.thumbnail"
      cover
      class="news-img"
    ></v-img>
    <v-card-title class="card-title px-5" :class="{ 'border-top': borderTop }">
      <div class="media-name">
        {{ mediaName }}
      </div>
      <div class="news-title">
        {{ title }}
      </div>
    </v-card-title>
  </v-card>
</template>
<script>
  export default {
    name: 'News',
    props: {
      url: {
        type: String
      },
      mediaName: {
        type: String
      },
      title: {
        type: String
      },
      imgUrl: {
        type: String,
        default: null
      },
      borderTop: Boolean
    },
    data: () => ({
      height: {
        container: 300,
        thumbnail: 150
      }
    }),
    methods: {
      openNewsTab(url) {
        window.open(url, '_blank');
      }
    }
  };
</script>
<style scoped>
  .card-container {
    --card-background: transparent;
    --card-background-hover: #2b303a;
    --card-primary: #ff2ef2;
    --card-img-background: #1f1f1f;

    overflow: hidden;
    background-color: var(--card-background);
    color: var(--v-surface-base);
  }
  .card-container:hover {
    background-color: var(--card-background-hover);
  }

  .card-title {
    font-size: small;
    gap: 0.75rem;
  }
  .card-title.border-top {
    border-top: 0.2rem solid var(--card-primary);
  }

  .card-title > div {
    line-height: 1rem;
  }

  .news-img {
    background-color: var(--card-img-background);
  }

  .media-name {
    display: flex;
    width: 100%;
    color: var(--card-primary);

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .news-title {
    display: flex;
    width: 100%;

    display: -webkit-box;
    max-height: 100px;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
