<template>
  <v-row class="my-8">
    <v-col
      v-for="(news, index) in data"
      cols="12"
      lg="12"
      :key="index"
      class="mt-lg-4"
    >
      <News
        :mediaName="news.imgTitle"
        :title="news.title"
        :url="news.url"
      ></News>
    </v-col>
    <div
      v-if="data.length && showExpand"
      v-observe-visibility="expandVisible"
    ></div>
  </v-row>
</template>

<script>
  import { NEWSROOM_DATA } from '@/constants';
  import News from '@/components/Newsroom/News.vue';

  export default {
    name: 'NewsList',
    props: {
      data: {
        type: Array,
        default: () => []
      },
      showExpand: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      newsList: NEWSROOM_DATA
    }),
    emits: ['onExpand'],
    methods: {
      async expandVisible(isVisible) {
        if (!isVisible) return;

        this.$emit('onExpand', true);
      }
    },
    components: { News }
  };
</script>
