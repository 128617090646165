<template>
  <div class="container wrapper px-8 px-lg-0">
    <v-row class="my-8">
      <h1>Newsroom</h1>
    </v-row>
    <NewsList
      :data="newsList"
      :showExpand="showExpand"
      @onExpand="getMoreNews"
    />
  </div>
</template>
<script>
  import { NEWSROOM_GET_NEWS_LIST } from '@/store/newsroom.module';

  import NewsList from '@/components/Newsroom/Sections/NewsList.vue';

  export default {
    name: 'Newsroom',
    components: { NewsList },
    data: () => ({
      newsPage: 0
    }),
    computed: {
      newsList() {
        return this.$store.state.newsroom.newsList.data;
      },
      showExpand() {
        const {
          pagination: { currentPage, lastPage }
        } = this.$store.state.newsroom.newsList;

        if (Number(currentPage) > 0) {
          return Number(currentPage) < Number(lastPage);
        }

        return true;
      }
    },
    methods: {
      async getMoreNews(pageIncrement = true) {
        if (pageIncrement) this.newsPage++;
        this.$store.dispatch(NEWSROOM_GET_NEWS_LIST, this.newsPage);
      }
    },
    mounted() {
      this.getMoreNews();
    }
  };
</script>
<style></style>
